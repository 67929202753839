<template>
  <div>
    <div class="card card-custom gutter-b">
      <!--begin::Header-->
      <div class="card-header">
        <h3 class="card-title font-weight-bolder text-dark">
          {{ $t("PERFORMANCE.COMPANY_DATA") }}
        </h3>
      </div>
      <!--end::Header-->

      <!--begin::Card Body-->
      <form class="form">
        <div class="card-body mx-4">
          <!-- FILTER AREA -->
          <EnergyFilter
            ref="energyFilter"
            :isGas="true"
            :isConsumption="true"
            :isRequiredMonth="true"
            :hasCompanyDetails="true"
            :hasPeriod="true"
            :hasGroups="false"
            :hasDay="false"
          ></EnergyFilter>

          <div class="row">
            <button
              @click="doPerformance"
              class="btn btn-primary btn-block"
            >
              {{ $t("PERFORMANCE.TITLE") }}
            </button>
          </div>
        </div>
      </form>
      <!--end::Card Body-->
    </div>

    <div class="row m-0" v-if="showCharts">
      <div class="col card card-custom gutter-b p-0">
        <!--begin::Header-->
        <div class="card-header">
          <h3 class="card-title font-weight-bolder text-dark">
            {{ $t("MENU.PERFORMANCE") }}
          </h3>
        </div>
        <!--end::Header-->

        <div class="card-body mx-4">
          <div>
            <apexchart
              style="height: 200px"
              type="line"
              height="200"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
        </div>
        <div class="mx-10">
          <p class="h6">{{ $t("PERFORMANCE.STATS") }}</p>
          <table class="table">
            <tbody>
              <tr v-for="row in stats" :key="row.name">
                <th>{{ row.name }}</th>
                <td>
                  {{
                    Number(row.value).toLocaleString(getLanguage(), {
                      maximumFractionDigits: 2,
                    })
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EnergyService from "@/core/services/energy.service";
import EnergyFilter from "@/view/content/filters/EnergyFilter.vue";

export default {
  name: "PerformanceGas",
  components: {
    EnergyFilter,
  },
  data() {
    return {
      showMenu: false,
      chartOptions: {},
      series: [],
      showCharts: false,
      colors: {
        "Power outage": "#fc2635", // red
        "Bad weather": "#fd8226", // orange
        Wind: "#8126fc", // purple
        Other: "#26fc81", // green
        Holiday: "#fc26a0", // pink
        transparent: "transparent",
        Outlier: "#008ffb", // blue
      },
      stats: [],
    };
  },
  methods: {
    getLanguage() {
      let language = localStorage.getItem("language");
      language = language === "ro" ? "ro-RO" : "en-US";
      return language;
    },
    async doPerformance() {
      let energyFilter = this.$refs.energyFilter;

      energyFilter.period.day = ""; // no day for gas

      this.showCharts = true;
      let response = await EnergyService.getPerformance(
        energyFilter.company.cui,
        energyFilter.group.group,
        "Gas",
        energyFilter.period
      );

      this.stats = response.stats;

      this.series = [
        {
          name: this.$t("PERFORMANCE.THRESHOLD_TOP"),
          type: "area",
          data: response.y_top,
        },
        {
          name: this.$t("PERFORMANCE.THRESHOLD_BOTTOM"),
          type: "area",
          data: response.y_bottom,
        },
        {
          name: this.$t("PERFORMANCE.DATA"),
          type: "line",
          data: response.y_real,
        },
        {
          name: this.$t("PERFORMANCE.PREDICTION"),
          type: "line",
          data: response.y_predicted,
        },
      ];

      this.chartOptions = this.getMonthChartOptions(response);
    },
    getMonthChartOptions(response) {
      return {
        chart: {
          title: {
            text: this.$t("MENU.PERFORMANCE"),
          },
          type: "line",
          stacked: false,
          height: 400,
        },
        colors: ["#bee2fd", "#ffffff", "#008ffb", "#fd8226"],
        fill: {
          type: "solid",
          opacity: 1,
        },
        xaxis: {
          type: "datetime",
          labels: {
            formatter: (_, timestamp) => {
              return new Date(timestamp).toLocaleString(this.getLanguage(), {
                month: "short",
                day: "numeric",
              });
            },
          },
        },
        yaxis: {
          show: true,
          showAlways: true,
          tickAmount: 8,
          title: {
            text: this.$t("DASHBOARD.CONSUME"),
          },
          labels: {
            formatter: function (value) {
              return Number(value).toLocaleString();
            },
          },
        },
        annotations: {
          points: this.eventsAnnotations(response).concat(
            this.outliersAnnotations(response)
          ),
        },
        tooltip: {
          shared: true,
          x: {
            formatter: (value) => {
              return new Date(value).toLocaleString(this.getLanguage(), {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric",
              });
            },
            y: {
              formatter: function (value) {
                return Number(value).toLocaleString() + " MW";
              },
            },
          },
        },
      };
    },
    outliersAnnotations(response) {
      let points = [];
      for (let i = 0; i < response.outliers.length; i++) {
        let value = response.outliers[i];
        let x = value[0];
        let y = value[1];
        points.push({
          x: x,
          y: y,
          marker: {
            size: 6,
            fillColor: "white",
            strokeColor: this.colors[value[2]], // TODO: depending on type (value[2])
            radius: 2,
          },
        });
      }
      return points;
    },
    eventsAnnotations(response) {
      let points = [];
      for (let i = 0; i < response.events.length; i++) {
        let value = response.events[i];
        let x = value[0];
        let y = value[1];
        points.push({
          x: x,
          y: y,
          marker: {
            size: 6,
            fillColor: "white",
            strokeColor: this.colors[value[2]], // TODO: depending on type (value[2])
            radius: 2,
          },
          label: {
            borderColor: this.colors[value[2]],
            style: {
              color: "#fff",
              background: this.colors[value[2]],
            },
            text: value[2],
          },
        });
      }
      return points;
    },
  },
  mounted() {},
};
</script>
